
  import { defineComponent, ref, watch } from 'vue';
  import { useI18n } from 'vue-i18n/index';
  import Datatable from '@/components/kt-datatable/KTDatatable.vue';
  import { useAbility } from '@casl/vue';

  export default defineComponent({
    name: 'factor-quality-values-table',
    components: {
      Datatable,
    },
    emit: ['show-edit-modal'],
    props: {
      value: { type: Object, required: true },
    },
    async setup(props, { emit }) {
      const tableData = ref(props.value);
      const tableTitle = ref(props.value.name);
      const factorId = ref(props.value.id);
      const { t, te } = useI18n();
      const { can } = useAbility();
      watch(
        () => props.value,
        (changed) => {
          tableData.value = changed;
        }
      );

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const tableHeader = ref([
        {
          name: translate('title'),
          key: 'title',
          sortable: true,
        },
        {
          name: translate('value'),
          key: 'value',
          sortable: true,
        },
      ]);

      const showEditIreModal = () => {
        emit('show-edit-modal', {
          value: props.value,
          factorId: 1,
          factorTitle: translate('quality'),
        });
      };

      return {
        factorId,
        translate,
        tableHeader,
        tableTitle,
        tableData,
        can,
        showEditIreModal,
      };
    },
  });
