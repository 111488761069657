import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card pt-4 mb-6 mb-xl-9"
}
const _hoisted_2 = { class: "card-header border-0" }
const _hoisted_3 = { class: "card-title m-0" }
const _hoisted_4 = { class: "fw-bolder m-0" }
const _hoisted_5 = { class: "card-toolbar" }
const _hoisted_6 = { class: "svg-icon svg-icon-3" }
const _hoisted_7 = { class: "card-body pt-0 pb-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_Datatable = _resolveComponent("Datatable")!

  return (_ctx.can('read', 'instant-rent-estimator'))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.tableTitle), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            (_ctx.can('update', 'instant-rent-estimator'))
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  type: "button",
                  class: "btn btn-sm btn-flex btn-light-primary me-3",
                  "data-bs-toggle": "modal",
                  "data-bs-target": "#kt_modal_edit_ive_value",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showEditIreModal && _ctx.showEditIreModal(...args)))
                }, [
                  _createElementVNode("span", _hoisted_6, [
                    _createVNode(_component_inline_svg, { src: "/media/icons/duotune/general/gen035.svg" })
                  ]),
                  _createTextVNode(" " + _toDisplayString(_ctx.translate('UpdateExistingValues')), 1)
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_Datatable, {
            "table-header": _ctx.tableHeader,
            "table-data": _ctx.tableData,
            "rows-per-page": 5,
            total: _ctx.tableData.length,
            "enable-items-per-page-dropdown": false
          }, {
            "cell-title": _withCtx(({ row: option }) => [
              _createTextVNode(_toDisplayString(option.title), 1)
            ]),
            "cell-value": _withCtx(({ row: option }) => [
              _createTextVNode(_toDisplayString(option.value), 1)
            ]),
            _: 1
          }, 8, ["table-header", "table-data", "total"])
        ])
      ]))
    : _createCommentVNode("", true)
}